<template>
  <div>
    <el-card>
      <el-row :gutter="40">
        <el-col :span="12">
          <el-form>
            <el-form-item label="所属分类">
              <el-select v-model="form.cate_id">
                <el-option v-for="(item,key) in cate_options" :key="key" :value="item.id" :label="item.title"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="画册名称">
              <el-input v-model="form.title"></el-input>
            </el-form-item>
            <el-form-item label="画册缩略图">
              <y-upload-img v-model="form.pic"></y-upload-img>
            </el-form-item>

            <el-form-item label="画册高度">
              <el-input-number v-model="form.height"></el-input-number>px
            </el-form-item>

            <el-form-item label="背景音乐">
              <y-upload-audio-box v-model="form.bg_audio"></y-upload-audio-box>
            </el-form-item>
            <el-form-item label="背景色">
              <y-choose-color v-model="form.bg_color"></y-choose-color>
            </el-form-item>
            <el-form-item label="背景图">
              <y-upload-img v-model="form.bg_img"></y-upload-img>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12">
          <el-form>
            <el-form-item label="自定义按钮图标">
              <y-upload-img v-model="form.custom_btn_icon"></y-upload-img>
            </el-form-item>
            <el-form-item label="自定义按钮链接">
              <y-choose-link v-model="form.custom_btn_url"></y-choose-link>
            </el-form-item>

            <el-form-item label="联系我们手机号">
              <el-input v-model="form.link_phone"></el-input>
            </el-form-item>
            <el-form-item label="联系我们微信号">
              <el-input v-model="form.link_wx_account"></el-input>
            </el-form-item>
            <el-form-item label="联系我们弹窗文本">
              <br>
              <y-editor v-model="form.link_rich_text"></y-editor>
            </el-form-item>

            <el-form-item>
              <el-button @click="edit" type="primary">提交</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import YUploadImg from "@/components/y-form/y-upload/y_upload_img";
import YChooseLink from "@/components/shop/y_choose_link";
import YEditor from "@/components/y-form/y-editor/y_editor";
import YUploadAudioBox from "@/components/y-form/y-upload/y_upload_audio";
import YChooseColor from "@/components/y-form/y_choose_color";
/**
 * 画册基础信息编辑 保存后返回列表
 */
export default {
  name: "edit",
  components: {YChooseColor, YUploadAudioBox, YEditor, YChooseLink, YUploadImg},
  data(){
    return{
      form:{
        cate_id:0,
        title:"",
        pic:"",

        height:0,

        bg_audio:"",
        bg_color:"",
        bg_img:"",

        link_rich_text:"",
        link_phone:"",
        link_wx_account:"",

        custom_btn_title:"",
        custom_btn_icon:"",
        custom_btn_url:"",
      },
      edit_id:0,

      cate_options:[],
    }
  },
  methods:{
    loadCateOptions(){
      this.$u.api.album.cateAll().then(res=>{
        res.unshift({
          id:0,
          title:"请选择所属分类"
        })
        this.cate_options = res;
      })
    },
    load(){
      this.$u.api.album.one({id:this.edit_id}).then(res=>{
        this.form = res;
      })
    },
    edit(){
      this.$u.api.album.edit(this.form).then(()=>{
        this.$message.success("操作成功");
        this.$router.back();
      })
    }
  },
  mounted() {
    this.loadCateOptions();
    if(this.$route.query.id){
      this.edit_id = parseInt(this.$route.query.id);
      this.load();
    }
  }
}
</script>

<style scoped>

</style>